import Flickity from "flickity"
window.Flickity = Flickity

import aa from "search-insights"
window.aa = aa

import Rails from "@rails/ujs"
Rails.start()

import "../cart"
import "../siteSearch"
import "../countrySelector"
import "../StoreFinder"
import "../components/modals"
import "../components/desktop-header"
import "../components/mobile-navigation"
import "../components/mini-cart"
import "../components/site-footer"
import "../components/contentful/hero-banner-with-curved-text"
import "../components/contentful/navigation-roundel"
import "../components/contentful/text-roundel"
import "../components/contentful/video-players"
import { zoomImageOnHover } from "./image-hover-zoom.js"
import { updateStitchYourPhotosTotal } from "./update-stitch-your-photos-total.js"

window.zoomImageOnHover = zoomImageOnHover
window.updateStitchYourPhotosTotal = updateStitchYourPhotosTotal
