// This code manages showing and hiding the drawers of the main site navigation
// as the user mouses over each tab.
window.addEventListener("DOMContentLoaded", () => {
  const header = document.getElementById("site-header")

  if (!header) {
    return
  }

  const closeDrawers = () => {
    header.querySelectorAll(".navigation-drawer--open").forEach((drawer) => {
      drawer.classList.remove("navigation-drawer--open")
    })

    header
      .querySelectorAll(".navigation-primary__link--selected")
      .forEach((link) => {
        link.classList.remove("navigation-primary__link--selected")
      })
  }

  header.addEventListener("mouseleave", (event) => {
    closeDrawers()
  })

  header.querySelectorAll(".navigation-primary__link").forEach((link) => {
    link.addEventListener("mouseenter", (event) => {
      closeDrawers()

      link.classList.add("navigation-primary__link--selected")

      const drawerId = link.dataset.drawerId

      const drawer = Array.from(
        header.querySelectorAll(".navigation-drawer")
      ).find((drawer) => drawer.dataset.drawerId == drawerId)

      drawer.classList.add("navigation-drawer--open")
    })
  })

  // This code applies the specified class when the header is "sticking".
  // More information: https://stackoverflow.com/a/57991537
  const observerOptions = {
    root: null,
    threshold: [1],
    rootMargin: `-5px 0px 0px 0px` // the top margin is here to prevent flickering when the class is added/removed
  }

  const observer = new IntersectionObserver(([entry]) => {
    const isSticky = !entry.isIntersecting
    document.body.classList.toggle("site-header--sticky", isSticky)
  }, observerOptions)

  observer.observe(header)
})
